.footer{
    align-items: center; 
    background-color: #0c0b28;
    display: flex;
    flex-direction: column; 
    height: fit-content;
    justify-content: center;
    padding: 2.2rem;
}


.footer-logo{
    height: 6.8rem;
    margin-bottom: 1.2rem;
}


