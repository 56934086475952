.about-text {
    /* color: #fff; */
    font-size: 2.4rem;
    line-height: 2.2;
    text-align: left;
    letter-spacing: 0.025rem;
}


.heading {
    font-size: 9.6rem;
    font-weight: 700;
    line-height: 1.05;
    letter-spacing: 1.05;
    text-align: center;
    margin-bottom: 9.6rem;
    color: #333;
}

.services-list {
    color: #fff;
    font-size: 2.4rem;
    line-height: 2.2;
    text-align: left;
    text-transform: uppercase;
}

/* Media Queries */
@media (max-width: 768px) {
    .heading {
      font-size: 4.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .heading {
      font-size: 2.8rem;
    }
  }