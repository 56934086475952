* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html{
  font-size: 62.5%;
  overflow-x: hidden;
  /* scroll-behavior: smooth; */
}

body{
  color: #555;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1;
  overflow-x: hidden;
}

strong{
  font-weight: 500;
}

@media(max-width: 75em){
  html{
    font-size: 56.25%;
  }
}

@media(max-width: 59em){
  html{
    font-size: 50%;
  }
}
