.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.grid {
  column-gap: 6.4rem;
  display: grid;
  row-gap: 9.6rem;
}

/* .grid:last-child{
    margin-bottom: 0
  } */

.grid:not(:last-child) {
  margin-bottom: 9.6rem;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

.grid--center-v {
  align-items: center;
}

.btn,
.btn:link, .btn:visited{
  display: inline-block;
  text-decoration: none;
  font-size: 2rem;
  padding: 1.6rem 3.2rem;
  border-radius: 9px;

  /*Always put transition on the original state*/
  /* transition: background-color 0.3s; */
  /*^ we want the border to transition too: all*/
  transition: all 0.3s;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: inherit;
}

.btn--form{
  background-color: #0c0b28;
  color: #fff;
  align-self: end;
  padding: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 1.5;
}

.btn--form:hover{
  background-color: #fff;
  color: #0c0b28;
}

.link:link,
.link:visited{
  display: inline-block;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
  transition: all 0.3s;
}

.heading-secondary {
  font-size: 4.4rem;
  font-weight: 700;
  line-height: 1.05;
  letter-spacing: 1.05;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 9.6rem;
  color: #333;
}

.logo {
  height: 5.4rem;
}



