.section-cta {
  margin-bottom: 9.6rem;
  margin-top: 9.6rem;
  padding: 4.8rem 0;
}

.cta {
  display: grid;
  grid-template-columns: 2fr 1fr;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 1.1rem;
  background-image: linear-gradient(to right top, rgba(212, 24, 88, 0.95), rgba(230, 112, 34, 0.75));
  overflow: hidden;
  padding-bottom: 9.6rem;
}

.cta-text-box {
  padding: 4.8rem 6.4rem 6.4rem 6.4rem;
}

.cta-text {
  padding: 1rem;
  font-size: medium;
  letter-spacing: 0.5px;
  line-height: 1.5;
  color: black;
}

.cta-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.4rem;
}

.form-row {
  display: flex;
  flex-direction: column;
}

.form-row.full-width {
  grid-column: 1 / -1;
}

.form-row label {
  color: #fff;
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
}

.form-row input,
.form-row select,
.form-row textarea {
  padding: 1.2rem;
  font-size: 1.8rem;
  color: #333;
  border: none;
  background-color: #fff;
  border-radius: 0.9rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.form-row textarea {
  resize: vertical;
}

.form-row button {
  align-self: flex-start;
}

.cta-image-box {
  background-image: url("../assets/logos/TransparentLogo.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 59em) {
  .cta {
    grid-template-columns: 3fr 2fr;
  }

  .cta-form {
    grid-template-columns: 1fr;
  }

  .btn--form {
    margin-top: 1.2rem;
  }
}

@media (max-width: 34em) {
  .grid {
    row-gap: 4.8rem;
  }

  .grid--2-cols,
  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: 1fr;
  }

  .btn,
  .btn:link,
  .btn:visited {
    padding: 2.4rem 1.6rem;
  }

  .cta {
    grid-template-columns: 1fr;
  }

  .cta-image-box {
    height: 32rem;
    grid-row: 1;
  }

  .cta-text-box {
    padding: 3.2rem;
  }
}
