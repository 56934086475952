.header {
    align-items: center;
    background-color: #0c0b28;
    display: flex;
    height: 12.8rem;
    justify-content: space-between;
    padding: 0 4.8rem;
    position: relative;
}

.main-nav-list {
    align-items: center;
    display: flex;
    gap: 4.8rem;
    list-style-type: none;
}

.main-nav-link:link,
.main-nav-link:visited {
    color: #fff;
    display: inline-block;
    font-weight: 500;
    font-size: 1.8rem;
    text-decoration: none;
    transition: all 0.3s;
}

.main-nav-link:hover,
.main-nav-link:active {
    color: #D41858;
}

.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
    background-color: #d01352;
    border-radius: 9px;
    color: #fff;
    padding: 1.2rem 2.4rem;
}

.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
    background-color: #D41858;
}

/* MOBILE */
.btn-mobile-nav {
    background: none;
    border: none;
    cursor: pointer;
    display: none;
}

.icon-mobile-nav {
    color: #333;
    height: 4.8rem;
    width: 4.8rem;
}

/* STICKY NAVIGATION */
.sticky .header {
    background-color: rgba(255, 255, 255, 0.90);
    bottom: 0;
    box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
    height: 12.8rem;
    padding-bottom: 0;
    padding-top: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

}

.heading-primary{
    font-size: 5.2rem;
    line-height: 1.05;
    color: #fff;
}

.sticky .heading-primary{
    color: #0c0b28;
}

.sticky .main-nav-link{
    color: #333;
}

.sticky .main-nav-link:hover,
.sticky .main-nav-link:active {
    color: #D41858;
}

.sticky .main-nav-link.nav-cta:hover,
.sticky .main-nav-link.nav-cta:active {
    color: #fff;
}

.title-container{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/********************************/
/* BELOW 944px (tablets)*/
/******************************/

@media(max-width: 59em) {
    .btn-mobile-nav {
        display: block;
        z-index: 9999;
    }

    .title-container{
        display: none;
    }

    .main-nav {
        align-items: center;
        background-color: rgba(255, 255, 255, 0.9);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(10px);
        display: flex;
        justify-content: center;
        height: 100vh;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transform: translateX(100%);
        transition: all 0.5s ease-in;
        width: 100%;
        visibility: hidden;
        z-index: 9999;
    }

    .nav-open .main-nav {
        opacity: 1;
        pointer-events: auto;
        transform: translateX(0);
        visibility: visible;
    }

    .nav-open .icon-mobile-nav[name="menu-outline"] {
        display: block;
    }

    .main-nav-list {
        flex-direction: column;
        gap: 4.8rem;
        position: fixed;
    }

    .main-nav-link:link,
    .main-nav-link:visited {
        color: #333;
        font-size: 3rem;
    }
    
}